// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../../styles/core/_variables.app";
@import "../../../../styles/core/_mixins";
@import "../../../../styles/core/_animations";
@keyframes messagePop {
    from {
        opacity: 0;
        transform: translate3d(0, 5px, 0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
        opacity: 1;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.messageItem {
    margin-top: 0.1rem;
    padding: 2px;
    position: relative;

    &:last-of-type {
        animation: messagePop 0.2s ease-in both;
    }

    // posted
    &.status-1 {
    }

    // removed
    &.status-2 {
    }

    // retracted
    &.status-3 {
    }

    // unchecked
    &.status-4 {
    }

    // Declined
    &.status-5 {
    }

    // removed & retracted
    &.status-2,
    &.status-3 {
        .icons {
            color: @danger-color;
        }
    }

    &:hover .actions {
        opacity: 1;
        transform: translateY(0);
    }
}

.header {
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgba(@muted-color, 0.5);
    font-size: 0.9em;
    position: relative;
    z-index: 1;
}

.main {
    .flexCenter();
    justify-content: flex-start;
    padding: 0 1.5rem;

    @media (max-width: 576px) {
        padding-right: 0;
    }

    &::before {
        content: "";
        position: absolute;
        left: 12px;
        top: 0;
        bottom: -2px;
        width: 1px;
        background: rgba(0, 0, 0, 0.01);
        z-index: 0;
        border-radius: 8px;

        .wrapper:last-child & {
            bottom: 25px;
        }
    }

    blockquote {
        margin: 0;
    }
}

.name {
    .flexCenter();
    font-size: 0.8rem;
    color: @text-color;
    font-weight: 500;
}

.quote {
    display: flex;
    margin-top: 5px;
    border-left: 2px solid rgba(black, 0.1);
    padding-left: 5px;
    font-size: 12px;

    &Author {
        font-weight: 600;
        padding: 0 5px;
    }
}

// Message
.balloon {
    position: relative; // for visibility controls
    margin: 5px 0;
    padding: 3px 9px;
    word-break: break-all;
    max-width: 560px;
    font-size: 14px;
    min-width: 45px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (hover: none) {
        cursor: pointer;
    }

    @media (max-width: 576px) {
        //max-width: 100%;
        //width: 100%;
        cursor: pointer;
    }

    // My messages (colorful)
    .sticker & {
        font-size: 64px;
        padding: 0;
        margin: 0;
    }

    .isSender:not(.sticker) & {
        background: linear-gradient(#3187df, #8585df);
        box-shadow: 0 6px 10px -2px rgba(#3187df, 0.25);
        color: white;
        border-radius: 8px 8px 0 8px;

        a {
            color: white;
            text-decoration-color: rgba(white, 0.5);
        }
    }

    // Other messages (white)
    .isRecipient:not(.sticker) & {
        color: @text-color;
        border-radius: 8px 8px 8px 2px;
        background: lighten(@background-alt, 2%);
        box-shadow: 0 6px 10px -2px rgba(#3a8ce221, 0.15);
    }

    .status-2 &,
    .status-3 & {
        .addTransition();
        opacity: 0.4;

        &:hover {
            opacity: 1;
        }
    }
}

.status {
    font-size: 12px;
    align-self: center;
    margin-left: 0.5rem;
}

.actions {
    transition: transform 0.1s ease;
    transform: translateX(-10px);
    opacity: 0;
    z-index: 10;

    // Center buttons
    display: flex;
    gap: 2px;
    align-items: center;

    &Overlay {
        :global {
            .ant-popover-inner-content {
                padding: 0;
            }
        }
    }

    &Inner {
        padding: 12px 16px;
        .flexCenter();
        gap: 10px;

        button {
            border: none;
            font-size: 1rem;

            &,
            &:hover,
            &:focus {
                background: transparent;
            }
        }

        :global {
            .fa-trash {
                color: @danger-color;
            }
        }

        &.inactive {
        }

        &:not(.inactive) {
        }

        .fa-reply {
            color: @info-color;
        }
    }
}

.thread {
    margin-left: 1.5rem;
}

.reply {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    color: @muted-color;
    word-break: break-all;

    .html {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
}

.status {
    .flexCenter();
    gap: 10px;
}

.emptyWrap {
    @media (max-width: 576px) {
        &::before,
        &::after {
            display: none;
        }

        :global(.ant-divider-inner-text) {
            width: 100%;
        }
    }
}

.empty {
    font-size: 13px;
    @media (max-width: 576px) {
        white-space: normal;
    }
}
