// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
// Dictionary
.wrapper {
    color: inherit;
    max-width: 100%;
    margin: 0;

  &.writerEmailStatuses {
    // Claimed
    &-1 {
      .tagVariant(@warning-color);
    }

    // Assigned
    &-2 {
      .tagVariant(@success-color);
    }

    // Replaced
    &-3 {
      .tagVariant(@success-color);
    }

    // Abandoned
    &-4 {
      .tagVariant(@danger-color);
    }

    // Removed
    &-5 {
      .tagVariant(@danger-color);
    }

    // Reclaimed
    &-6 {
      .tagVariant(@success-color);
    }

    // Restored
    &-7 {
      .tagVariant(@success-color);
    }
  }

  &.writerPayoutEmailStatuses {
    &-1 {
      .tagVariant(@warning-color);
    }

    &-2 {
      .tagVariant(@success-color);
    }

    &-3 {
      .tagVariant(@success-color);
    }

    &-4 {
      .tagOutlined(@danger-color);
    }

    &-5 {
      .tagOutlined(@danger-color);
    }

    &-6 {
      .tagOutlined(@danger-color);
    }

  }

  &.writerFieldOfStudyStatuses {
    &-1 {
      .tagVariant(@warning-color);
    }

    &-2 {
      .tagVariant(@info-color);
    }

    &-3 {
      .tagVariant(@danger-color);
    }

    &-4 {
      .tagVariant(@success-color);
    }

    &-5 {
      .tagVariant(@gray-color);
    }

    &-6 {
      .tagVariant(@danger-color);
    }
  }

  &.writerPassStatuses {
    // Draft
    &-1 {
      .tagVariant(@warning-color);
    }

    // Requested
    &-2 {
      .tagVariant(@warning-color);
    }

    // Considered
    &-3 {
      .tagVariant(@pending-color);
    }

    // Declined
    &-4 {
      .tagOutlined(@danger-color);
    }

    // Signed
    &-5 {
      .tagVariant(@success-color);
    }

    // Revoked
    &-6 {
      .tagOutlined(@danger-color);
    }

    // Withdrawn
    &-7 {
      .tagOutlined(@danger-color);
    }

    // Removed
    &-8 {
      .tagOutlined(@danger-color);
    }
  }

  &.writerFileVerificationStatuses {
    // NotVerified
    &-1 {
      .tagVariant(@warning-color);
    }

    // Pending
    &-2 {
      .tagVariant(@pending-color);
    }

    // Verified
    &-3 {
      .tagOutlined(@success-color);
    }

    // Dismissed
    &-4 {
      .tagOutlined(@danger-color);
    }

  }

  &.writerWriterSkillStatuses {
    &-1 {
      .tagVariant(@warning-color);
    }

    &-2 {
      .tagVariant(@info-color);
    }

    &-3 {
      .tagVariant(@danger-color);
    }

    &-4 {
      .tagVariant(@success-color);
    }

    &-5 {
      .tagVariant(@black-color);
    }

    &-6 {
      .tagVariant(@danger-color);
    }

  }

  &.writerPassWriterFileStatuses {
    //Claimed
    &-1 {
      .tagVariant(@warning-color);
    }

    //Withdrawn
    &-2 {
      .tagOutlined(@danger-color);
    }

    //Imposed
    &-3 {
      .tagVariant(@success-color);
    }

    //Accepted
    &-4 {
      .tagVariant(@success-color);
    }

    //Declined
    &-5 {
      .tagOutlined(@danger-color);
    }

    //Removed
    &-6 {
      .tagOutlined(@danger-color);
    }
  }

  &.writerFileAppearanceStatuses {
    &-1 {
      .tagVariant(@success-color);
    }

    &-2 {
      .tagVariant(@muted-color);
    }

    &-3 {
      .tagOutlined(@danger-color);
    }

  }

  &.revisionStatuses {

    // Draft
    &-1 {
      .tagVariant(@warning-color);
    }

    // Claimed
    &-2 {
      .tagVariant(@warning-color);
    }

    // Pending
    &-3 {
      .tagVariant(@pending-color);
    }

    // InWork
    &-4 {
      .tagVariant(@inwork-color);
    }

    // Submitted
    &-5 {
      .tagVariant(@success-color);
    }

    // Completed
    &-6 {
      .tagOutlined(@success-color);
    }

    // Canceled
    &-7 {
      .tagOutlined(@danger-color);
    }

    // Withdrawn
    &-8 {
      .tagOutlined(@black-color);
    }

    // Disputed
    &-9 {
      .tagVariant(@danger-color);
    }

    // Declined
    &-10 {
      .tagOutlined(@danger-color);
    }

    // Removed
    &-11 {
      .tagOutlined(@danger-color);
    }

    // Unable to commence
    &-12 {
      .tagVariant();
    }

    // Unable to proceed
    &-13 {
      .tagVariant();
    }

    // Aborted
    &-14 {
      .tagOutlined(@danger-color);
    }

  }


  &.writerOrderStatuses {
    // Claimed
    &-1 {
      .tagVariant(@warning-color);
    }

    // Assigned
    &-2 {
      .tagOutlined(@pending-color);
    }

    // InWork
    &-3 {
      .tagVariant(@info-color);
    }

    // Submitted
    &-4 {
      .tagVariant(@success-color)
    }

    // Unassigned
    &-5 {
      .tagVariant(@danger-color)
    }


    // Aborted
    &-6 {
      .tagOutlined(@danger-color)
    }

    // Dismissed
    &-7 {
      .tagOutlined(@muted-color);
    }

    // Paused
    &-8 {
      .tagVariant(@muted-color);
    }

    // Withdrawn
    &-9 {
      .tagOutlined(@danger-color);
    }

    // Rejected
    &-10 {
      .tagOutlined(@danger-color);
    }

    // Pending
    &-11 {
      .tagVariant(@pending-color);
    }

    // UnableToProceed
    &-13 {
      .tagOutlined(@muted-color);
    }

    // Ready
    &-14 {
      .tagVariant(@success-color);
    }
    // Completed
    &-15 {
      .tagOutlined(@success-color);
    }
  }

  &.writerOrderWriterFileStatuses {
    // Claimed
    &-1 {
      .tagVariant(@warning-color);
    }

    // Confirmed
    &-2 {
      .tagOutlined(@success-color);
    }

    // Set
    &-3 {
      .tagOutlined(@success-color);
    }

    // Removed
    &-4 {
      .tagOutlined(@danger-color);
    }

    // Withdrawn
    &-5 {
      .tagOutlined(@danger-color);
    }
  }


  &.customerFileVerificationStatuses {
    // NotVerified
    &-1 {
      .tagVariant(@warning-color);
    }

    // Pending
    &-2 {
      .tagVariant(@pending-color);
    }

    // Verified
    &-3 {
      .tagOutlined(@success-color);
    }

    // Dismissed
    &-4 {
      .tagOutlined(@danger-color);
    }
  }

  &.revisionUserFileStatuses {
    // Set
    &-1 {
      .tagOutlined(@success-color);
    }

    // Removed
    &-2 {
      .tagOutlined(@danger-color);
    }

  }
  &.revisionRevisedWriterFileStatuses,
  &.revisionWriterFileStatuses,
  &.revisionCustomerFileStatuses {
    // Claimed
    &-1 {
      .tagVariant(@warning-color);
    }

    // Set
    &-2 {
      .tagOutlined(@success-color);
    }

    // Confirmed
    &-3 {
      .tagOutlined(@success-color);
    }

    // Removed
    &-4 {
      .tagOutlined(@danger-color);
    }


    // Withdrawn
    &-5 {
      .tagOutlined(@danger-color);
    }

    // Removed
    &-6 {
      .tagOutlined(@danger-color);
    }

  }

  &.gradingStatuses {
    // Draft
    &-1 {
      .tagVariant(@warning-color);
    }

    // Pending
    &-2 {
      .tagVariant(@pending-color);
    }

    // InWork
    &-3 {
      .tagVariant(@info-color);
    }

    // Completed
    &-4 {
      .tagOutlined(@success-color)
    }

    // Canceled
    &-5 {
      .tagOutlined(@danger-color)
    }


    // Removed
    &-6 {
      .tagOutlined(@danger-color)
    }
  }

  &.ticketStatuses {
    // Draft
    &-1 {
      .tagVariant(@warning-color);
    }

    // Pending
    &-2 {
      .tagVariant(@pending-color);
    }

    // InWork
    &-3 {
      .tagVariant(@info-color);
    }

    // Resolved
    &-4 {
      .tagOutlined(@success-color)
    }

    // Canceled
    &-5 {
      .tagOutlined(@danger-color)
    }


    // Removed
    &-6 {
      .tagOutlined(@danger-color)
    }
    // Withdrawn
    &-7 {
      .tagOutlined(@danger-color)
    }
  }

  &.writerPaymentStatuses {
    // Draft
    &-1 {
      .tagVariant(@warning-color);
    }

    // Verified
    &-2 {
      .tagVariant(@success-color);
    }

    // Paid
    &-3 {
      .tagOutlined(@success-color);
    }

    // Canceled
    &-4 {
      .tagOutlined(@danger-color);
    }

    // Removed
    &-5 {
      .tagOutlined(@danger-color);
    }
    // Prepared
    &-6 {
      .tagVariant(@warning-color);
    }
  }

  &.writerPaymentTypes {
    // Default
    &-1 {
      .tagOutlined(@black-color);
    }

    // Bonus
    &-2 {
      .tagOutlined(@success-color);
    }

    // Penalty
    &-3 {
      .tagOutlined(@danger-color);
    }

    // Pass bonus
    &-4 {
      .tagOutlined(@success-color);
    }
  }

  &.writerPaymentBatchStatuses {
    // Prepared
    &-1 {
      .tagVariant(@pending-color);
    }

    // Paid
    &-2 {
      .tagOutlined(@success-color);
    }

    &-3 {
      .tagOutlined(@danger-color);
    }

  }

  &.plagCheckStatuses {
    // Draft
    &-1 {
      .tagVariant(@warning-color);
    }

    // Pending
    &-2 {
      .tagVariant(@pending-color);
    }

    // Passed
    &-3 {
      .tagVariant(@success-color);
    }

    // Failed
    &-4 {
      .tagVariant(@danger-color);
    }

    // Canceled
    &-5 {
      .tagOutlined(@danger-color);
    }

    // Removed
    &-6 {
      .tagOutlined(@danger-color);
    }

  }


  &.writerFieldCompetenceStatuses {
    // Verified
    &-1 {
      .tagVariant(@success-color);
    }

    // Imposed
    &-2 {
      .tagVariant(@success-color);
    }

    // Relinquished
    &-3 {
      .tagOutlined(@danger-color);
    }

    // Deprived
    &-4 {
      .tagOutlined(@danger-color);
    }

  }

  &.writerFieldCompetenceInterimStatuses {
    // Claimed
    &-1 {
      .tagVariant(@warning-color);
    }

    // Rejected
    &-2 {
      .tagOutlined(@danger-color);
    }

    // Withdrawn
    &-3 {
      .tagOutlined(@danger-color);
    }
  }

  &.writerSkillCompetenceStatuses {
    // Verified
    &-1 {
      .tagVariant(@success-color);
    }

    // Imposed
    &-2 {
      .tagVariant(@success-color);
    }

    // Relinquished
    &-3 {
      .tagOutlined(@danger-color);
    }

    // Deprived
    &-4 {
      .tagOutlined(@danger-color);
    }

  }

  &.writerSkillCompetenceInterimStatuses {
    // Claimed
    &-1 {
      .tagVariant(@warning-color);
    }

    // Rejected
    &-2 {
      .tagOutlined(@danger-color);
    }

    // Withdrawn
    &-3 {
      .tagOutlined(@danger-color);
    }
  }

  &.orderDrawingStatuses {
    // Draft
    &-1 {
      .tagVariant(@warning-color);
    }

    // Ongoing
    &-2 {
      .tagVariant(@info-color);
    }

    // Paused
    &-3 {
      .tagVariant(@muted-color);
    }

    // Closed
    &-4 {
      .tagOutlined(@danger-color);
    }

    // Aborted
    &-5 {
      .tagOutlined(@danger-color);
    }

    // Removed
    &-6 {
      .tagOutlined(@danger-color);
    }

    // Completed via Appointment
    &-7 {
      .tagOutlined(@success-color);
    }

    // Completed via Weighted Draw
    &-8 {
      .tagOutlined(@success-color);
    }

  }

  &.testQuizUndertakingStatuses {
    // Prepared
    &-1 {
      .tagVariant(@warning-color);
    }

    // InWork
    &-2 {
      .tagVariant(@inwork-color);
    }

    // Submitted
    &-3 {
      .tagVariant();
    }

    // Passed
    &-4 {
      .tagOutlined(@success-color);
    }

    // Failed
    &-5 {
      .tagOutlined(@danger-color);
    }

    // Expired
    &-6 {
      .tagOutlined(@danger-color);
    }
  }



  &.dotTag {
    .size(10px);
    display: inline-flex;
    border-radius: 50%;
    color: transparent;
    text-align: center;
    line-height: 1rem;
    min-height: auto;
    padding: 0;
    position: relative;
    top: -1px; // perfect pixel

    &::first-letter {
      font-size: 10px;
    }
  }



}






