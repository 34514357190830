// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_animations";
// Widget
.wrapper {
    align-self: flex-start;

    &:not(.bordered) {
        .widget();
    }

    // @DEPRECATED
    //& > .wrapper {
    //    margin-top: 15px;
    //}
    //
    //& + .wrapper {
    //    margin-bottom: 15px;
    //}

    .wrapper {
        .card;
    }
}

.bordered {
    .widgetAlt();
}
