// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.spinner {
    min-height: calc(100vh - 150px); // 120px is amount height of header and footer
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: fixed;

    :global {
        .ant-spin-dot {
            animation-timing-function: cubic-bezier(0.4, 0, 0.04, 1.71);
            animation-duration: 2.2s;
            width: 40px;
            height: 40px;
        }

        span.ant-spin-lg {
            .ant-spin-dot {
                font-size: 10rem;
            }
        }

        i.ant-spin-dot-item {
            width: 10px;
            height: 10px;

            :local {
                animation: dots-resize 1s ease 0.5s infinite;
            }

            opacity: 0.5;

            &:nth-child(1) {
                background: @primary-color;
            }

            &:nth-child(2) {
                background: @primary-color;
            }

            &:nth-child(3) {
                background: @primary-color;
            }

            &:nth-child(4) {
                background: @primary-color;
            }
        }

        .ant-spin-text {
            margin-top: 1rem;
            color: @primary-color;
            font-weight: bold;
        }
    }
}
