// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.wrapper {
    .defaultShadows();
    background: @background-alt;
    border-radius: @border-radius;
    max-height: 730px;
    width: 410px;
    max-width: 90%;
    position: fixed;
    right: 1rem;
    bottom: -10px;
    padding-top: 5px;
    animation: fadeInUp 0.3s ease both;

    &.hidden {
        animation: fadeInRight 0.2s ease both;
        cursor: pointer;
        width: 40px;
        height: 40px;
        bottom: 65vh;
        right: -5px;
    }

    @media (max-width: 400px) {
        margin-bottom: 2rem;
    }
}

.heading {
    padding: 0 10px;
    margin: 0 0 5px 0;
}

.id {
    font-size: 10px;
    position: absolute;
    bottom: 1px;
    left: 1px;
}

.link {
    color: @text-color;
    display: flex;
    align-items: center;
    gap: 5px;
}
