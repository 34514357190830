// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../../styles/core/_variables.app";
@import "../../../../styles/core/_mixins";
@import "../../../../styles/core/_animations";
// MessageForm
.wrapper {
    z-index: 11; // MessageItem & Channels tabs
    position: sticky;
    bottom: 0;
    margin: 10px 0 0;
    background: @background-alt;

    :global {
        .bf-container {
            margin: 0;
            display: flex;
            flex-flow: column;

            border: 1px solid rgba(@border-color-base, 0.75);
            border-radius: @border-radius;

            .widget(5px);

            [data-contents="true"] {
                padding: 0;
            }
        }

        .bf-content {
            margin: 0;
        }

        .bf-controlbar {
            order: 1;
        }
    }

    &.disabled {
        .preview {
            display: none;
        }

        .editorWrap {
            :global(.ant-input-textarea) {
                position: absolute;
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}

@keyframes slide-up {
    from {
        transform: translateY(10px);
    }
    to {
        transform: translateY(0);
    }
}

// Thread preview
.preview {
    .widget(5px 10px);
    background: white;
    animation: slide-up 0.4s ease both;
    transform: translateY(10px);
    justify-content: space-between;
    position: relative;
    top: 5px;
    width: 95%;
    margin: auto;

    &,
    span {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.footer {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;

    @media (max-width: 576px) {
        flex-wrap: wrap;
        justify-content: flex-start;
        position: relative;
        > span {
            display: flex;
            align-items: center;
            min-height: 40px;
        }
    }

    .hint {
        font-size: 12px;
        color: @muted-color;

        @media (hover: none) {
            display: none;
        }

        @media (max-width: 576px) {
            display: none;
        }
    }

    .submit {
        display: flex;
        gap: 10px;
        align-items: center;

        @media (max-width: 576px) {
            width: 100%;
            flex-direction: column;
        }
    }
}

.footerDropdownBtn {
    @media (max-width: 576px) {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
    }
}

.submitBtn {
    @media (max-width: 768px) {
        min-height: 40px;
    }

    @media (max-width: 576px) {
        width: 100%;
    }
}

.textareaVariant(@color) {
    textarea {
        &,
        &:focus,
        &:hover {
            border: 2px solid @color;
        }
    }
    button[type="submit"] {
        background: @color;
        color: white;
        border: 1px solid @color;
        box-shadow: 0 4px 8px rgba(@color, 0.2);
    }
}

// TODO: rename "recipient"
.recipient textarea {
    .textareaVariant(@border-color);
}

.public {
    .textareaVariant(#1890ff);

    .disabledIcon {
        color: #1890ff;
    }
}

.user {
    .textareaVariant(@user-color);
    .disabledIcon {
        color: @user-color;
    }
}

.customer {
    .textareaVariant(@customer-color);

    .disabledIcon {
        color: @customer-color;
    }
}

.writer {
    .textareaVariant(@writer-color);

    .disabledIcon {
        color: @writer-color;
    }
}

.private,
.all {
    .textareaVariant(@user-color);

    .disabledIcon {
        color: @user-color;
    }
}

.editorWrap {
    position: relative;
}

.disabledOverlay {
    padding: 7px 12px;
    display: flex;
    width: 100%;
    gap: 10px;
    min-height: 78px;
    background-color: #f3f4f7;
    border: 2px solid #dfebf4;
    border-radius: 8px;
}

.disabledIcon {
    width: 16px;
    flex-shrink: 0;
}

.disabledText {
    color: @muted-color;
}
