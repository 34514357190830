// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../../styles/core/_variables.app";
@import "../../../../styles/core/_mixins";
@import "../../../../styles/core/_animations";
.wrapper {
    position: relative;
}

.chat {
    max-height: 50vh; // i'm not sure!
    overflow-y: auto;
    border-bottom: 1px solid var(--border-color);
    position: relative;
    margin: -5px 0;

    :global {
        .ant-tabs-nav {
            background: var(--widget-bg-color, #f9fbfd);
            backdrop-filter: blur(2px);
            position: sticky;
            top: 0;
            z-index: 11;
        }
        .wide {
            .ant-tabs {
                margin: 0;
            }

            .ant-tabs-nav {
                height: 200px;
                margin-right: 2px;
                padding: 0;

                .ant-tabs-tab {
                    padding: 0.2rem 1rem 0.2rem 0.2rem;
                    border-radius: 6px;
                    font-size: 13px;

                    &.ant-tabs-tab-active {
                    }
                }
            }

            .ant-tabs-content {
                min-height: 250px;
            }
        }
    }
}

.status {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 1.4;
    user-select: none;
}
