// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_animations";
.wrapper {
}

span.badge {
    margin-left: 3px;
}

.dotVariant(@color) {
    background-color: @color;
    box-shadow: 0 2px 4px rgba(@color, 0.4);
}

.dot {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 8px;
    height: 8px;
    margin-right: 4px;
    background-color: var(--primary-color, #1890ff);
    border-radius: 50%;

    &Default {
        .dotVariant(#1890ff);
    }

    &Muted {
        .dotVariant(#d7e2ee);
    }

    &Success {
        .dotVariant(@success-color);
    }

    &Danger {
        .dotVariant(@danger-color);
    }

    &Warning {
        .dotVariant(@warning-color);
    }

    &Customer {
        .dotVariant(@customer-color);
    }

    &Writer {
        .dotVariant(@writer-color);
    }
}
