// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
// SpaceBetween
.wrapper {
    align-items: flex-start;
    gap: 5px;
    display: flex;
    justify-content: space-between;
}
