// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.noBg {
    .detailItem {
        &:nth-child(n) {
            background-color: transparent;
        }
    }
}

.alignLeft {
    .detailItem {
        justify-content: flex-start;
    }

    .detailDescription {
        text-align: left;
    }
}

.detailItem {
    padding: 7px 4px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:nth-child(odd) {
        background-color: @background-alt;
    }

    &:nth-child(even) {
        background-color: #fff;
    }
}

.inverted {
    .detailItem {
        &:nth-child(odd) {
            background-color: #fff;
        }
        &:nth-child(even) {
            background-color: @background-alt;
        }
    }
}

.detailTitle {
    color: @muted-color;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    margin-right: 8px;
    white-space: nowrap;
}

.detailDescription {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: @black-color;
    text-align: right;
    flex: 1;
    word-break: break-word;
}

.detailItemPrimary {
    .detailDescription {
        text-align: left;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .sm({
            white-space: normal;
            word-break: break-word;
        });
    }
}

.muted {
    opacity: 0.1;
    transition: opacity 0.2s ease;
    &:hover {
        opacity: 1;
    }
}
